.editor {
    counter-reset: line;
    border: 1px solid #ced4da;
    border-radius: 8px;
}

.editor #codeArea {
    outline: none;
    padding-left: 60px !important;
}

.editor pre {
    padding-left: 60px !important;
}

.ql-snow .ql-tooltip {
    position: revert;
}

.editor .editorLineNumber {
    position: absolute;
    left: 0px;
    color: #cccccc;
    text-align: right;
    width: 40px;
    font-weight: 100;
}

.dropHours>div {
    padding: 7px 15px;
}

.dropTextArea {
    min-height: 144px !important;
}

.GACognitivesection label {
    top: -9px !important;
}

.AssessmentTime label {
    top: -11px !important;
    margin-top: 4px;
}

.FormCheck {
    display: flex;
    flex-direction: row;
}

.FormCheck label {
    display: inline;
    width: 30%;
}

[data-color-mode*="dark"],
[data-color-mode*="dark"] body {
    --color-canvas-subtle: #161b22;
}

[data-color-mode*="light"],
[data-color-mode*="light"] body {
    --color-canvas-subtle: #f6f8fa;
}

/* 
table#u_body table:first-child{
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
}

table#u_body table:first-child tbody a[target*="_blank"] {
    animation: slideInRight 1.5s ease-in-out;
} */

.delay-effect table#u_body table:nth-child(2), .delay-effect table#u_body table:nth-child(3), 
.delay-effect table#u_body table:nth-child(4), .delay-effect table#u_body table:nth-child(5),
.delay-effect table#u_body table:nth-child(6), .delay-effect table#u_body table:nth-child(7){
 visibility: hidden;

}
.delay-effect table#u_body table:first-child, 
#simulationSec table[role="presentation"] div > table[role="presentation"]:first-child {

  animation: delayEffect 0.8s ease-in-out forwards;
  animation-delay: 0s;

}


.delay-effect table#u_body table:not(:first-child),
.delay-effect table[role="presentation"] table:not(:first-child) {
    visibility: hidden;
    opacity: 0;
}


.delay-effect table#u_body table:nth-child(2),
.delay-effect table[role="presentation"] table:nth-child(2) {
    animation: delayEffect 0.8s ease-in-out forwards;
    animation-delay: 0.5s;
}


.delay-effect table#u_body table:nth-child(3),
.delay-effect table[role="presentation"] table:nth-child(3) {
    animation: delayEffect 0.8s ease-in-out forwards;
    animation-delay: 1.3s; /* Previous delay + animation duration */
}


.delay-effect table#u_body table:nth-child(4),
.delay-effect table[role="presentation"] table:nth-child(4) {
    animation: delayEffect 0.8s ease-in-out forwards;
    animation-delay: 2.1s;
}


.delay-effect table#u_body table:nth-child(5),
.delay-effect table[role="presentation"] table:nth-child(5) {
    animation: delayEffect 0.8s ease-in-out forwards;
    animation-delay: 2.9s;
}


.delay-effect table#u_body table:nth-child(6),
.delay-effect table[role="presentation"] table:nth-child(6) {
    animation: delayEffect 0.8s ease-in-out forwards;
    animation-delay: 3.7s;
}


.delay-effect table#u_body table:nth-child(7),
.delay-effect table[role="presentation"] table:nth-child(7) {
    animation: delayEffect 0.8s ease-in-out forwards;
    animation-delay: 4.5s;
}

@keyframes delayEffect {
    0% {
        visibility: hidden;
        transform: translateX(-100%);
        opacity: 0;
    }
    1% {
        visibility: visible;
    }
    100% {
        visibility: visible;
        transform: translateX(0);
        opacity: 1;
    }
}

.animationBtn button {
    margin-right: 12px;
}

.animationBtn button svg {
    margin-bottom: 8px;
    scale: 1.4;
    color: #606060 !important;
}

.animationBtn button:hover {
    background-color: #eeeeee !important;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.17) -2px 7px 20px;
    border: 1px solid rgb(212, 212, 212);
    animation: 0.9s ease 1.5s infinite normal none running shake;
}
 
  .scroll-text-right table#u_body table {
    animation: slideInLeft 0.5s ease-in-out !important;
  }
  
  .scroll-text-left table#u_body table{
    animation: slideInRight 0.5s ease-in-out !important;
  } 
  
  .pop-up-animation table#u_body table {
    animation: popUp 0.5s ease-in-out !important;
  }

  .search-select-container input, #selectionQue + input{
    font-size: 14px;
    padding: 9px 12px;
    border-color: #ddd;
    width: 100%;
    outline: none;
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 6px;
  }
  .search-select-container ul, ul[aria-labelledby="searchable-select-label"]{
  list-style: none;
  max-height: 120px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  margin-top: 2px;
  overflow: auto;
}
.search-select-container li{
  list-style: none;
  padding: 3px 0 4px 12px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  /* border-bottom: 1px solid rgba(145, 158, 171, 0.32); */
}
.search-select-container .selected-option{
  overflow: hidden;
}
.search-select-container .selected-option p{
  font-weight: 400;
  padding-top: 4px;
  font-size: 15px;
}
  @keyframes slideInRight {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInLeft {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes popUp {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes animationdelayEffect {
    0% {
  
      transform: translateX(-100%);
      opacity: 0;
    }
    50% {
   
      transform: translateX(50%);
      opacity: 0.5;
    }
    100% {
  
      transform: translateX(0);   
      opacity: 1;
    }
  }

  @keyframes delayEffectTwo {
    0% {
      visibility: hidden;
      transform: translateX(-100%);
      opacity: 0;
    }
    1% {
      visibility: visible; /* Make visible when animation starts */
    }
    100% {
      visibility: visible;
      transform: translateX(0);   
      opacity: 1;
    }
  }

  @keyframes delayEffectThree {
    0% {
      visibility: hidden;
      transform: translateX(-100%);
      opacity: 0;
    }
    1% {
      visibility: visible; /* Make visible when animation starts */
    }
    100% {
      visibility: visible;
      transform: translateX(0);   
      opacity: 1;
    }
  }
  @keyframes delayEffectFour {
    0% {
      visibility: hidden;
      transform: translateX(-100%);
      opacity: 0;
    }
    1% {
      visibility: visible; /* Make visible when animation starts */
    }
    100% {
      visibility: visible;
      transform: translateX(0);   
      opacity: 1;
    }
  }
  @keyframes delayEffectFive {
    0% {
      visibility: hidden;
      transform: translateX(-100%);
      opacity: 0;
    }
    1% {
      visibility: visible; /* Make visible when animation starts */
    }
    100% {
      visibility: visible;
      transform: translateX(0);   
      opacity: 1;
    }
  }
  @keyframes delayEffectSix {
    0% {
      visibility: hidden;
      transform: translateX(-100%);
      opacity: 0;
    }
    1% {
      visibility: visible; /* Make visible when animation starts */
    }
    100% {
      visibility: visible;
      transform: translateX(0);   
      opacity: 1;
    }
  }
  @keyframes delayEffectSeven {
    0% {
      visibility: hidden;
      transform: translateX(-100%);
      opacity: 0;
    }
    1% {
      visibility: visible; /* Make visible when animation starts */
    }
    100% {
      visibility: visible;
      transform: translateX(0);   
      opacity: 1;
    }
  }

