.Googlelogo{
    flex-direction: row-reverse;
    justify-content: center;
    padding: 0 6px !important;
    width: 120px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 6px !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}
.textLabel{
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #212b21; 
    line-height: 0.1em;
    margin: 10px 0 20px;  
}
.textLabel span{
    background:#fff; 
    padding:0 10px; 
}
.Googlelogo div{
    margin: 0px !important;
    padding: 0px !important;
}

.Googlelogo span{
    padding: 0px !important;
    margin-right: 6px !important;
    font-size: 14px !important;
    font-family: 'Plus Jakarta Sans';
}
.logoSection{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.logoSection button{
    width: 120px;
    margin-right: 10px;
    color: #838383 !important;
    padding : 0 6px !important;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.logoSection button:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}


