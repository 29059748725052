#simulationSec [class^="makeStyles-content-"],
#simulationSec [class^="makeStyles-body-"],
#simulationSec [class^="makeStyles-gridContainer-"]{
    width: 100%;
}

#simulationSec [class^="makeStyles-keycellanimation-"]{
  width: 100% !important;
}

/* #simulationSec [class^="makeStyles-content-"] , #simulationSec [class^="makeStyles-body-"],  */
#simulationSec [class^="makeStyles-gridContainer-"] > div table#u_body{
  height: calc(100vh - 200px);
}

#image-container, #image-container [class^="makeStyles-content-"] {
    width: 1022px;
}

#simulationSec [class^="makeStyles-body-"] ,
.containerHighLight + div > div:has(> button){
  display: flex;
  justify-content: center;
}
.preventZoom {
  touch-action: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
#simulationSec  li {
  list-style-position: inside;
  text-align: start;
}

@media screen and (max-width: 600px) {
  .simulationScreen{
    width: 100% !important;
  }
}