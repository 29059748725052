.rh5v-Overlay_inner {
  background-color: #fe9700ed !important;
  width: 50px;
  height: 41px;
}
.rh5v-Volume_icon {
  padding: 0px;
}
.rh5v-Fullscreen_icon {
  padding: 0px;
}

.css-1ffk4d9-MuiButtonBase-root-MuiButton-root {
  display: none;
}
.rh5v-Seek_fill {
  background: #fe9700ed;
}

.rh5v-PlayPause_icon {
  padding: 0px;
}

.rh5v-Time_current {
  margin-right: 5px;
  /* color: #fe9700ed; */

}
.rh5v-Time_duration {
  margin-left: 5px;
  /* color: #fe9700ed; */
}
.badgeShower {
  margin-right: 20px !important;
}
.rh5v-Volume_fill {
  background-color: #fe9700ed;
}

.rh5v-Fullscreen_button {
  /* background-color:  #fe9700ed; */
}

.rh5v-DefaultPlayer_controls {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  /* height: 34px; */
  display: flex;
  opacity: 0;
  border-radius: 50px !important;
  /* transition: opacity 0.2s; */
  /* padding: 0px 63px 18px 61px; */
  padding: 0px 22px;
}

.rh5v-DefaultPlayer_component {
  position: relative;
  font-family: Helvetica;
  font-size: 11px;
  background-color: #000;
  border-radius: 15px;
}

.rh5v-DefaultPlayer_video {
  width: 100%;
  height: 100%;
  padding: 3px 4px 3px 6px;
  border-radius: 10px;
}


html {
  scroll-behavior: smooth;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.move-out {
  transform: translateY(-50px);
  /* Move card up on cancel */
  opacity: 0;
  /* Fade out effect */
}

.popup-content h2 {
  margin: 0;
  font-size: 1.5em;
}

.start-btn,
.cancel-btn {
  margin-top: 15px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.5s ease;
  /* Smooth transition for moving the button */
}

.start-btn {
  background-color: #007BFF;
  color: white;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
}

.moved {
  position: absolute;
  /* Allow the button to move outside */
  left: 50%;
  /* Center it */
  transform: translateX(-50%) translateY(-60px);
  /* Move it up and center */
  transition: all 0.5s ease;
  /* Smooth movement */
}


/* Animation effect for the Test button when it's outside */
.test-button-outside {
  margin-top: 20px;
  transition: all 0.3s ease;
}

/* Button returns back to Open Popup place */
.button-return {
  transition: all 0.3s ease;
  transform: translateY(-20px);
  /* Move up by 20px */
  opacity: 0.5;
  /* Fades the button */
}

/* Styling for the popup container */
.popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.cardShowEven, .cardShowEvens {
  display: flex !important;

}
.cardShowEven{
  align-items: end;
}

.buyNowbtn, .buyNowbtnOne{
  background: #FE7000;
  border-radius: 4px;
  color: #fff;
  border: 1px solid #FE7000;
   float: right;
}
.buyNowbtn:hover, .buyNowbtnOne:hover{
  background: #fff;
  border: 1px solid #FE7000;
  border-radius: 4px;
  color: #FE7000;
}
.buyNowbtn{
  margin-right: 16px;
}
.highlights li p{
  margin-bottom: 8px;
  line-height: 1.1;
}

.tab button{
  color: #404040;
}
.swiper-slide h6 ~ p > p{
  height: 33px;
  overflow: hidden;
  color: #000;
}
.swiper-button-next:after, .swiper-button-prev:after{
  font-size: 14px !important;
  background: #000000b0;
  border-radius: 50%;
  color: #fff;
  width: 21px;
  height: 21px;
  top: 0px;
  position: absolute;
  display: flex;
  justify-content: center;
  padding-top: 4px;
  font-weight: 600;
}
.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  cursor: auto;
  opacity: .45;
  pointer-events: none;
}
/* .reviewBtn{
  visibility: hidden;
} */
.reviewBtn:before{
  content: '';
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 15px 15px 0px 15px;
  border-color: #fff transparent transparent transparent;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  bottom: -45px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.swiper-button-prev{
  left: -2px !important;
}

.iconExplore > div[class*="Mui-expanded"]:last-child{
  transform: rotate(90deg) !important;
}
.TestScoreViewBoard .MuiSlider-track {
  border: 8px solid ;
}
.TestScoreViewBoard .MuiSlider-thumb {
  width: 28px !important;
  height: 28px !important;
}
.css-ttgsjq-MuiSlider-track{
  border: 6px solid ;
}
.MuiSlider-rail {
height: 6px
}
.sectionTab:first-child{
padding-left: 0 !important;
}
.sectionTabText {
  justify-content: start;
  padding: 5px;
}  
.analysisSection button.transit:hover{
border-color: blue;
background-color: #f5f5f5;
}
#QuestionsContent > p + p{
  display: none;
} 
#QuestionsContent > p span{
  color: unset !important;
}
.sectionTabText{
  margin-bottom: 0 !important;
}
@media screen and (max-width: 1250px) {
  .graphBox{
    width: 33% !important;
  }
}

@media screen and (max-width: 
500px) {
  h6 .subModuleName {
    text-align: start !important;
    line-height: 1;
    font-size: 15px;
    margin-bottom: 4px;
  }

  .fontdetailstxt>div {
    display: block;
    width: 100%;
    margin: 0;
  }

  .gridB,
  .CourseContentSec,
  .numGridB {
    max-width: 320px !important;

  }

  .barGraph .MuiAvatar-circular>img,
  .barGraph .MuiAvatar-circular {
    width: 30px;
    height: 30px;
  }

  .groupBtn button {
    padding: 0 5px !important
  }

  .groupBtn {
    flex-wrap: wrap !important;
  }

  .logDetails p:last-child {
    font-size: 11px !important;
  }

  .contentPara {
    margin-left: 60px !important;
    margin-top: -83px !important;
  }

  .barGraph .css-o8szfe {
    width: 35px;
  }

  .CourseContentSec {
    margin-left: 15px !important;
  }
}

@media screen and (min-width: 769px) {

  .logDetails>div>div {
    padding: 12px !important;
  }

  .logDetails p.fontdetails {
    font-weight: bold !important;
  }

  .subModuleSec p {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1000px) {
  .priceIs {
    font-size: 22px;
  }
}
@media screen and (min-width: 500px) {
  h6.fontdetails>span {
    align-self: flex-start !important;
  }

  .RightCore h6.fontdetails>span {
    align-self: center !important;
  }
}

@media screen and (max-width: 768px) {
  .cardShowEven, .cardShowEvens {
    flex-direction: column !important;
  }
.tabBasic{
  height: 55px !important;
}
.titleAssessment{
  min-height: 40px !important;
}
  .badgeShower {
    margin-right: 2px !important;
  }
  .buyNowbtnOne {
    width: 100%;
    border-radius: 0;
    margin-top: 10px;
    margin-right: 0 !important;
  }
}